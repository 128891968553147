import { navigate } from "gatsby";
import React, { useState, useEffect, useContext } from "react";
import Activity, { instanceOfActivity } from "shared-models/build/activities";
import ActivityTag from "shared-models/build/activity_tags";
import Country from "shared-models/build/countries";
import Tag, { instanceOfTag } from "shared-models/build/tag";
import { FirebaseAuthContext } from "../../context/firebase-auth";
import { sendPostRequest, toastError } from "../../util/util";
import Select from "react-select";
import CountryActivity, { instanceOfCountryActivity } from "shared-models/build/country_activities";

const ModifyActivity = () => {
    const [tagMap, setTagMap] = useState<{ [key: number] : Tag }>([]);
    const user = useContext(FirebaseAuthContext);
    const [activity, setActivity] = useState<Activity>({
        Id: 0,
        Name: "",
        Description: "",
        Settings: {},
        Verified: false,
        ActivityPictureUrl: "",
    });
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const [countries, setCountries] = useState<Country[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<Country[]>([]);
    const [activityCountries, setActivityCountries] = useState<{ [key: number]: number[] }>([]);
    

    // Initialize 
    useEffect(() => {
        // Get the data
        const params = new URLSearchParams(window.location.search);
        const data = params.get("data");
        if (data === null) return;
        const parsedData = JSON.parse(data);

        if (!("Activity" in parsedData)) return;

        let activityTags = [];
        if ("ActivityTags" in parsedData) {
            activityTags = parsedData.ActivityTags;
        }
        
        // Set the Properties
        if (instanceOfActivity(parsedData.Activity)) {
            setActivity(parsedData.Activity);
            
            const initSelectedTags = activityTags.reduce((arr: Tag[], actTag : ActivityTag) => {
                if (!(actTag.TagId in tagMap)) return;
                return [
                    ...arr,
                    tagMap[actTag.TagId],
                ]
            }, []);
            setSelectedTags(initSelectedTags);
        }
    }, [tagMap]);

    useEffect(() => {
        if (user !== null) {
            getAllTags();
            getAllCountries();
        }
    }, [user]);

    useEffect(() => {
        if (activityCountries[activity.Id]) {
            const counts = activityCountries[activity.Id];
            const filteredCountries = countries.filter((cnt: Country) => {
                const activityContainsCountry = counts.findIndex((val) => val === cnt.Id);
                if (activityContainsCountry >= 0) {
                    return cnt;
                }
            })
            setSelectedCountries(filteredCountries);
        }
    }, [countries, activityCountries, activity])

    const getAllCountries = async () => {
        if (user === null) return;
        const [resp, err] = await sendPostRequest(user, "/country/get-countries", {}, "", "Failed to get the countries");
        if (err !== null) {
            console.log({ resp, err });
            return;
        }
        setCountries(resp.Countries);

        const initMap = resp.CountryActivities?.reduce((obj: { [key: number]: [] }, ca: CountryActivity) => {
            if (!(instanceOfCountryActivity(ca))) return obj;
            if (ca.ActivityId in obj) {
                const temp = obj[ca.ActivityId];
                return {
                    ...obj,
                    [ca.ActivityId]: [...temp, ca.CountryId]
                }
            }
            return {
                ...obj,
                [ca.ActivityId]: [ca.CountryId]
            };
        }, {});
        setActivityCountries(initMap)
    };

    const getAllTags = async () => {
        if (user === null) return;
        const [tags, err] = await sendPostRequest(user, "/tags/get-verified-tags", {}, "", "Failed to get tags.");
        if (err !== null) {
            console.log(err);
            return;
        }

        if (Array.isArray(tags)) {
            const tagMapInit = tags.reduce((obj : { [key: string]: Tag }, tg) => {
                if (!instanceOfTag(tg)) return obj;
                return {
                    ...obj,
                    [tg.Id]: tg,
                }
            }, {})
            setTagMap(tagMapInit);
        }
    }

    const handleActivityChange = (key: string, value: any) => {
        setActivity({
            ...activity,
            [key]: value,
        });
    }
    
    const handleSelectTag = (id : string) => {
        const numericalId = +String(id);
        const tagFromId = Object.values(tagMap).find((val) => val.Id === numericalId);
        const tagExits = selectedTags.findIndex((val) => val.Id === numericalId);
        if (tagExits >= 0) {
            toastError("Tag already added");
            return;
        }
        if (tagFromId) {
            setSelectedTags([
                ...selectedTags,
                tagFromId,
            ])
        }
    }

    const removeTag = (index: number) => {
        const duplicate = [...selectedTags];
        duplicate.splice(index, 1);
        setSelectedTags(duplicate);
    }

    const saveActivity = async () => {
        if (activity.Name.length === 0 || activity.Description.length === 0 || activity.ActivityPictureUrl.length === 0) {
            return toastError("Name, desc, or pic length is 0");
        };
        if (user === null) return;

        const selectedActivityCountries = selectedCountries.reduce((arr: any[], val: Country) => {
            return [...arr, [val.Id, activity.Id]];
        }, []);

        const [resp, err] = await sendPostRequest(user, "/activity/modify-activity", { Activity: activity, Tags: selectedTags, SelectedCountries: selectedActivityCountries }, "Saved activity", "Failed to save activity");
        if (err !== null) {
            console.error(resp, err);
            return;
        }
    }

    const deleteActivity = async () => {
        if (user === null) return;
        const [resp, err] = await sendPostRequest(user, "/activity/delete-activity", { Activity: activity }, "Deleted activity", "Failed to delete activity");
        if (err !== null) {
            console.log({ resp, err });
        }
    }

    return (
        <div>
            <h2 className="tw-my-4 tw-font-bold tw-text-lg">Modify Activity</h2>
            <div className="tw-my-4">
                <h3>Name</h3>
                <input 
                    className="tw-input"
                    value={activity.Name}
                    onChange={(e) => handleActivityChange("Name", e.target.value)}
                />
            </div>
            <div className="tw-my-4">
                <h3>Description</h3>
                <input 
                    className="tw-input"
                    value={activity.Description}
                    onChange={(e) => handleActivityChange("Description", e.target.value)}
                />
            </div>
            <div className="tw-my-4">
                <h3>Activity Picture URL</h3>
                <input 
                    className="tw-input"
                    value={activity.ActivityPictureUrl}
                    onChange={(e) => handleActivityChange("ActivityPictureUrl", e.target.value)}
                />
            </div>
            <div className="tw-my-4">
                <h3>Verified</h3>
                <input 
                    type="checkbox"
                    checked={activity.Verified}
                    onChange={(e) => handleActivityChange("Verified", e.target.checked)}
                />
            </div>
            <div className="tw-my-4">
                <h3 className="tw-mt-4 tw-font-bold">Tags</h3>
                <select name="Tags" id="tags" multiple onChange={e => handleSelectTag(e.target.value)}>
                    {
                        Object.values(tagMap).map((tagObj) => {
                            if (!instanceOfTag(tagObj)) return null;
                            return (
                                <option value={tagObj.Id} key={tagObj.Id}>
                                    {tagObj.Name}
                                </option>
                            )
                        })
                    }
                </select>
                <h3 className="tw-mt-4 tw-font-bold">Selected Tags</h3>
                <div className="tw-flex tw-flex-wrap tw-gap-8">
                {
                    selectedTags?.map((st, index) => {
                        return (
                            <div className="tw-border tw-p-2" key={index}>
                                {st.Name} <button onClick={() => removeTag(index)} className="tw-red-button tw-p-2">Remove</button>
                            </div>
                        )
                    })
                } 
                </div>
                <div className="tw-my-16">
                    <h3 className="tw-font-bold tw-text-lg">Select Activity Country or Countries</h3>
                    <p>I.e. Hot air balloon safari could be in multiple countries</p>
                    <Select
                    value={selectedCountries}
                    isMulti className="basic-multi-select" 
                    options={countries} 
                    getOptionLabel={(cnt : Country) => cnt.Name}
                    getOptionValue={(cnt : Country) => String(cnt.Id)}
                    onChange={(newValue) => {
                        setSelectedCountries([ ...newValue ]);
                    }} />
                </div>
            </div>
            <div className="tw-mt-8">
                <button className="tw-white-button" onClick={() => {
                    saveActivity();
                    navigate("/admin-team/activity")
                }}>
                    Save Activity
                </button>
            </div>
            
            {
                activity.Id > 0 ?
                <div className="tw-mt-8">
                    <button className="tw-red-button" onClick={() => {
                        deleteActivity();
                        handleActivityChange("Id", 0);
                    }}>
                        Delete Activity
                    </button>
                </div>
                :
                null
            }
        </div>
    )
}

export default ModifyActivity;